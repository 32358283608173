import {Pipe, PipeTransform} from '@angular/core';

const DIGIT_REGEX = /^\d$/;

@Pipe({name: 'phoneFormat'})
export class PhoneFormatPipe implements PipeTransform {
    transform(input: string): string {
        return PhoneFormatPipe.transform(input).formatted;
    }

    static transform(value: string): {formatted: string; unFormatted: string} {
        if (!value) return {formatted: '', unFormatted: ''};
        if (value.length < 2 || !this.hasValidCountryCode(value)) {
            return {
                formatted: value,
                unFormatted: PhoneFormatPipe.cleanUpNumbers(value) as string,
            };
        }

        const phoneNumber = PhoneFormatPipe.cleanUpNumbers(value.slice(PhoneFormatPipe.getCountryCodeLength(value)), false) as string[];

        const parts = {
            area: '',
            exchange: '',
            number: '',
        };

        phoneNumber.forEach((char, i) => {
            if (i <= 2) {
                parts.area += char;
            } else if (i <= 5) {
                parts.exchange += char;
            } else {
                parts.number = parts.number += char;
            }
        });

        if (parts.area.length) {
            parts.area = `(${parts.area}`;
        }

        if (parts.area.length === 4) {
            parts.area += ') ';
        }

        if (parts.exchange.length === 3) {
            parts.exchange += '-';
        }

        return {
            unFormatted: phoneNumber?.length ? `+1${phoneNumber.join('')}` : null,
            formatted: `+1 ${parts.area}${parts.exchange}${parts.number}`,
        };
    }

    static hasValidCountryCode(value: string) {
        return value.includes('+');
    }

    static getCountryCodeLength(value: string) {
        const countryCode = '+1 ';

        for (let i = 0; i < countryCode.length; i++) {
            const testCode = countryCode.slice(0, countryCode.length - i);
            if (value.includes(testCode)) {
                return testCode.length;
            }
        }

        return 0;
    }

    static cleanUpNumbers(value: string, join = true): string | string[] {
        const cleanedValue = value
            .trim()
            .split('')
            .filter(char => DIGIT_REGEX.test(char));

        if (join) return cleanedValue.join('');

        return cleanedValue;
    }
}
